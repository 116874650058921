@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';
@import '~@andes/button/lib/styles/size/medium';

$options-line-height: 1.7;

.#{$search-prefix}-main--without-header:not(.ui-search-main--without-breadcrumb) {
  .#{$search-prefix}-view-options__container {
    margin-top: 4px;
  }
}

.#{$search-prefix}-main--only-products {
  .#{$search-prefix}-view-options__container {
    margin-bottom: 16px;

    .#{$search-prefix}-styled-label.screen-reader-only {
      @include sr-only;
    }
  }
}

.ui-search-skeleton__mockup-main {
  .#{$search-prefix}-view-options {
    &__content {
      border: none;
    }

    &__container {
      left: 62.8%;

      @media (width <= 1220px) and (width >= 1120px) {
        left: 66%;
      }

      @media (width <= 1119px) {
        left: 49.1%;
      }
    }
  }
}

.#{$search-prefix}-view-options__hide-tooltip {
  display: none;
}

.#{$search-prefix}-main-pi-view-options {
  .andes-dropdown {
    margin-top: -4px;
  }

  .andes-dropdown.andes-dropdown--small {
    .andes-dropdown__trigger {
      display: inline-block;
      padding: 0;
    }

    .andes-dropdown__arrow {
      margin-left: 8px;
    }
  }
}

.#{$search-prefix}-view-options {
  display: inline-block;
  position: relative;
  min-width: max-content;
  width: max-content;

  .andes-list__item-text,
  .andes-dropdown--small .andes-dropdown__trigger {
    color: $search-gray-r090;

    .andes-list__item-primary {
      font-size: $font-size-14;
    }
  }

  .ui-search-sort-filter {
    .andes-widther {
      width: auto;
    }

    .andes-dropdown__popover {
      z-index: 1000;
    }
  }

  .ui-search-sort-filter__dropdown.andes-dropdown.andes-dropdown--standalone.andes-dropdown--small {
    .andes-floating-menu .andes-dropdown__trigger {
      padding: 0;
    }
  }

  .andes-dropdown--small {
    .andes-dropdown__arrow::after {
      border-right: 0.12111em solid $search-dodger-blue;
      border-bottom: 0.12111em solid $search-dodger-blue;
    }
  }

  &__content {
    display: inline-flex;
    align-items: center;
  }

  &__container {
    margin-bottom: 16px;
    text-align: right;

    .#{$search-prefix}-styled-label.screen-reader-only {
      @include sr-only;
    }
  }

  &__group {
    height: 100%;
    margin-bottom: -1px;
    display: flex;

    .andes-dropdown__display-values {
      margin-top: 2px;
      margin-left: $search-spacing-1;
    }
  }

  &__group--map {
    height: 100%;
    display: flex;
    align-self: center;
    align-items: center;

    .andes-dropdown__display-values {
      margin-top: 2px;
      margin-left: $search-spacing-1;
    }
  }

  &__title {
    color: $search-gray-1000;
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    line-height: $options-line-height;
    margin-right: $search-spacing-6;
    white-space: nowrap;
  }

  .#{$search-prefix}-map {
    &__button-container--link {
      line-height: $options-line-height;
    }

    &__button {
      display: inline-block;
      margin-right: $andes-spacing-16;
    }

    &__icon {
      display: block;
      margin-right: $search-spacing-6;
    }

    &__link {
      margin-right: $andes-spacing-20;
      margin-bottom: 0;
    }
  }

  &__container--map &__content {
    align-items: center;
  }

  .#{$search-prefix}-map__button-container {
    .andes-button__text {
      margin-left: 0;
    }
  }
}
