@import '../../../../styles/variables';

.#{$search-prefix}-item__shipping--free {
  color: $andes-success-green;
}

.shops__item-shipping-free {
  padding: $andes-spacing-4 $andes-spacing-12;
  border-radius: 16px;
}
