@import '../../../sidebar/styles/dropdown.desktop';
@import '../../../../styles/variables';

.#{$search-prefix}-sort-filter {
  &__title {
    color: $andes-gray-550-solid;
    font-size: $font-size-16;
    font-weight: $font-weight-semibold;
    margin: 0 0 $andes-spacing-12;
  }

  .andes-dropdown.andes-dropdown--open .andes-dropdown__trigger {
    box-shadow: none;
  }

  .andes-dropdown {
    .andes-card__content {
      min-width: 135px;
      overflow: hidden;

      @media (width <= 770px) {
        min-width: 125px;

        .andes-floating-menu .andes-list__item.andes-list__item--size-medium {
          padding-right: 10px;
        }
      }
    }
  }

  .andes-widther {
    height: 16px;
    width: 120px;
  }

  &--with-disclaimer {
    margin-top: 0;

    .andes-dropdown__trigger:first-child::after {
      content: '';
      position: relative;
      right: 29px;
      top: 3px;
      font-size: 19px;
    }

    .andes-dropdown__arrow {
      margin-left: $search-spacing-18;
    }
  }

  .andes-list__item {
    &--with-disclaimer {
      .andes-list__item-text::after {
        content: $disclaimer-asterisk;
        font-size: 19px;
        position: absolute;
        bottom: $andes-spacing-12;
      }
    }

    .andes-list__item-primary {
      font-size: $font-size-14;
      color: $andes-gray-900-solid;
    }

    &--selected {
      .andes-list__item-primary {
        color: var(--andes-color-blue-500, var(--andes-color-blue-500, #3483fa));
        font-weight: 600;
      }
    }
  }
}

.#{$search-prefix}-sort-filter-values {
  color: $andes-gray-550-solid;
  cursor: pointer;
  display: block;
  font-size: $font-size-14;
  width: 100px;
  position: relative;
  top: 2px;
  font-weight: $font-weight-regular;
}
