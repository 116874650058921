@import './variables';
@import '~@andes/common/lib/styles/mixins/sizing';

@mixin toolbar-buttons {
  align-items: center;
  display: flex;
  font-size: $font-size-14;
  height: 52px;
  justify-content: center;
  font-weight: $font-weight-regular;

  &:active,
  &:focus,
  &:hover,
  &:visited {
    color: $andes-blue-500;
  }
}

@mixin toolbar-buttons-icons {
  @include size(14px, 14px);

  margin-right: $andes-spacing-8;

  svg {
    fill: $brand-main;
  }
}

@mixin toolbar-buttons-label {
  width: 52px;
  text-align: center;
}

@mixin text-crop($top-adjustment, $bottom-adjustment) {
  &::before,
  &::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
  }

  &::before {
    margin-top: -#{$top-adjustment};
  }

  &::after {
    margin-bottom: -#{$bottom-adjustment};
  }
}

@mixin text-expand($top-adjustment, $bottom-adjustment) {
  &::before,
  &::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
  }

  &::before {
    margin-top: #{$top-adjustment};
  }

  &::after {
    margin-bottom: #{$bottom-adjustment};
  }
}

@mixin sr-only {
  display: flex;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@mixin filter-color {
  @each $id, $color in $filter-colors {
    &-#{$id} {
      background-color: $color;
    }
  }
}
