@import '../../../styles/variables';
@import '../../../styles/common';
@import '~@andes/button/index';
@import '~@andes/modal/index';
@import '~@andes/modal/base';
@import '~@andes/breadcrumb/index';
@import '~@andes/dropdown/base';
@import '~@andes/dropdown/index';
@import '~@andes/list/index';
@import '~@andes/dropdown/lib/styles/standalone/size/small';
@import '../../../components-v2/icons/styles/icons.scss';
@import '../../../components/sidebar/components/view-options/styles/view-options.desktop';
@import '../../../components-v2/filters/filter-sort/styles/filter-sort';
@import '../../../components/view-change/styles/view-change.desktop';
@import '../../../components/rescue/styles/rescue.desktop';
@import '../../../components-v2/sidebar/components/breadcrumb/styles/breadcrumb.desktop';

// sass-lint:disable no-vendor-prefixes, no-qualifying-elements
main[role='main'] {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  flex-grow: 1;
  height: auto;
}

body[data-site='PI'] {
  & > div:first-child {
    display: none;
  }

  & > main[role='main'] {
    height: 100%;
  }

  & > main[role='main'] .ui-search-rescue--zrp {
    .ui-search-rescue__link--decline {
      display: block;
      text-align: left;
      font-size: 16px;

      &:first-of-type {
        margin-top: 16px;
      }
    }

    .ui-search-rescue__icon {
      width: 80px;
    }
  }
}
