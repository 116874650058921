$border-zrp-color: $andes-bg-primary;
$title-zrp-color: #dc7b1c;

.#{$search-prefix}-rescue {
  $self: &;

  display: flex;
  flex-direction: column;
  padding: 18px 13px 32px;
  background-color: $andes-white;

  &--zrp {
    max-width: 1018px;
    min-height: 204px;
    border-radius: $border-radius-4;
    margin: 110px auto 430px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 42px 40px 32px;
  }

  &--zrp h3 {
    font-size: $font-size-24;
    font-weight: $font-weight-semibold;
    line-height: 1.08;
  }

  &__icon {
    margin-top: -8px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 35px;
  }

  &__list {
    margin: 18px 10px 10px 0;
    font-size: $font-size-16;
    font-weight: $font-weight-light;
  }

  &__item {
    line-height: 1.231;
    color: $andes-gray-900;
    list-style-type: disc;
    list-style-position: outside;
    margin-bottom: 3px;
    margin-left: 18px;
  }

  &__button {
    margin: $andes-spacing-16 0;
  }

  &--adults {
    padding: 1px 64px;
    font-size: $font-size-16;
    color: $search-gray-1000;
    font-weight: $font-weight-light;

    #{$self}__list {
      margin: 14px 5px 24px 0;
    }

    #{$self}__item {
      margin: 0;
      list-style: none;

      a {
        margin-left: 4px;
      }
    }

    #{$self}__button {
      @include size(142px, 36px);

      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      border-radius: $border-radius-4;
    }

    .#{$search-prefix}-warning__message {
      padding-left: 8px;
    }

    .#{$search-prefix}-icon--warning {
      transform: translate(3px, 0);
    }
  }

  &--eshop_zrp {
    padding-bottom: 18px;

    #{$self}__link--decline {
      margin-top: 16px;
    }
  }

  &__modal-actions {
    margin-top: 18px;
    display: flex;
    align-items: center;
  }

  &__modal-actions a {
    margin-right: 30px;
  }

  &__modal {
    width: 816px;
    height: 294px;
    border-radius: 4px;

    & .andes-modal__header {
      padding: 38px 0;
      margin-top: 2px;
    }

    & .andes-modal__title {
      color: $search-gray-1000;
      font-size: $font-size-20;
      padding-left: 64px;
    }

    & .andes-modal__close-button {
      right: 64px;
      top: 40px;
      position: fixed;
      stroke: $search-dodger-blue;
      filter: invert(50%) sepia(54%) saturate(6591%) hue-rotate(207deg) brightness(107%) contrast(96%);
    }

    & a:focus {
      outline: none;
    }
  }
}

.#{$search-prefix}-skeleton {
  &__top-keywords {
    font-size: 13px;
    padding-top: 16px;
    padding-bottom: 12px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__sidebar {
    max-width: 294px;
    min-width: 210px;
    float: left;
    padding-right: 22px;
  }

  &__filters-group {
    margin: 20px 0;
  }

  &__filters-group dt {
    background-color: $search-gray-600;
    display: block;
    height: 11px;
    margin-bottom: 20px;
    width: 50%;
  }

  &__filters-group dd {
    background-color: $search-gray-500;
    display: block;
    height: 10px;
    margin: 16px 0;
  }

  &__filters-group dd:nth-of-type(1) {
    width: 60%;
  }

  &__filters-group dd:nth-of-type(2) {
    width: 50%;
  }

  &__filters-group dd:nth-of-type(3) {
    width: 55%;
  }

  &__filters-group dd:nth-of-type(4) {
    width: 45%;
  }

  &__grid {
    display: flex;
    width: 900px;
    flex-wrap: wrap;
    float: left;
    overflow: hidden;
  }

  &__grid-item {
    display: table;
    height: 100%;
    width: 284px;
    background-color: $search-gray-100;
    color: $search-gray-1000;
    margin: 0 0 16px 16px;
    border-radius: $border-radius-4;
    box-shadow: 0 1px 1px 0 $search-gray-r010, 0 -1px 2px 0 $search-gray-r010;
    position: relative;
  }

  &__grid-image {
    width: 284px;
    height: 284px;
    background-color: $search-gray-500;
    border-radius: $border-radius-4 $border-radius-4 0 0;
    overflow: hidden;
  }

  &__grid-info {
    height: 174px;
    padding: $andes-spacing-24;
  }

  &_text-mockup {
    margin: $andes-spacing-20 0;
  }

  &__text-mockup dt {
    background-color: $search-gray-500;
    display: block;
    height: 11px;
    width: 35%;
  }

  &__text-mockup dd {
    background-color: $search-gray-500;
    display: block;
    height: 10px;
    margin: 10px 0;
  }

  &__text-mockup dd:nth-of-type(1) {
    width: 100%;
  }

  &__text-mockup dd:nth-of-type(2) {
    width: 60%;
  }
}

.ui-search {
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 10px;
}

.text-mock {
  background-color: $search-gray-500;
  display: block;
  height: 10px;
  width: 50%;
}

.text-mock.text-mock__breadcrumb {
  height: 9px;
  width: 55%;
}
