@import '../../../styles/variables';
@import '../../../styles/mixins';

$icon-size: 16px;

.#{$search-prefix}-view-change {
  align-items: center;
  display: flex;
  height: 26px;
  justify-content: flex-start;

  &::before {
    background-color: $search-gray-600;
    height: $icon-size;
    margin: 1px 13px 1px 15px;
    width: 1px;
    content: '';
  }

  .#{$search-prefix}-view-change__link {
    align-items: center;
    color: $andes-gray-550;
    display: flex;
    height: $icon-size;
    justify-content: center;
    margin: 0 $andes-spacing-4;
    transition: opacity $quick-transition-duration;
    width: $icon-size;

    &--active,
    &:hover {
      color: $search-dodger-blue;
      opacity: 1;
    }
  }
}
