$andes-theme: 'mercadolibre';

@import '~@andes/common/index';

// sass-lint:disable no-color-keywords

// Prefixes
$search-prefix: 'ui-search';
$category-trends-prefix: 'ui-category-trends';
$best-seller-prefix: 'ui-best-seller';
$faceted-mobile-prefix: 'faceted-search-mobile';
$faceted-desktop-prefix: 'faceted-search-desktop';
$andes-pagination-prefix: 'andes-pagination';

// Spacing
$search-spacing-1: 1px;
$search-spacing-2: 2px;
$search-spacing-3: 3px;
$search-spacing-4: 4px;
$search-spacing-5: 5px;
$search-spacing-6: 6px;
$search-spacing-7: 7px;
$search-spacing-8: 8px;
$search-spacing-9: 9px;
$search-spacing-10: 10px;
$search-spacing-12: 12px;
$search-spacing-13: 13px;
$search-spacing-14: 14px;
$search-spacing-16: 16px;
$search-spacing-17: 17px;
$search-spacing-18: 18px;
$search-spacing-20: 20px;
$search-spacing-22: 22px;
$search-spacing-24: 24px;
$search-spacing-25: 25px;
$search-spacing-28: 28px;
$search-spacing-30: 30px;
$search-spacing-32: 32px;
$search-spacing-36: 36px;
$search-spacing-38: 38px;
$search-spacing-42: 42px;
$search-spacing-52: 52px;
$search-spacing-96: 96px;
$search-spacing-100: 100px;

// Fonts
$font-size-7: 7px;
$font-size-8: 8px;
$font-size-9: 9px;
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-36: 36px;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold-light: 500;
$font-weight-semibold: 600;
$line-height-14: 14px;
$line-height-15: 15px;
$line-height-16: 16px;
$line-height-18: 18px;
$line-height-20: 20px;
$line-height-22: 22px;
$line-height-25: 25px;
$line-height-125: 1.25;

// Colors
$search-white-900: rgb(255 255 255 / 90%);
$search-white-100: #fff;
$search-gray-5: rgb(0 0 0 / 4%);
$search-gray-10: #eee;
$search-gray-100: #fafafa;
$search-gray-90: rgb(0 0 0 / 90%);
$search-gray-120: rgb(0 0 0 / 12%);
$search-gray-150: rgb(0 0 0 / 15%);
$search-gray-160: rgb(0 0 0 / 16%);
$search-gray-200: #f5f5f5;
$search-gray-250: #f3f3f3;
$search-gray-300: #f0f0f0;
$search-gray-350: rgb(0 0 0 / 35%);
$search-gray-400: #dedede;
$search-gray-450: rgb(0 0 0 / 45%);
$search-gray-500: #ddd;
$search-gray-550: rgb(0 0 0 / 55%);
$search-gray-620: rgb(0 0 0 / 62%);
$search-gray-600: #bfbfbf;
$search-gray-666: #666;
$search-gray-700: #999;
$search-gray-750: #dedede;
$search-gray-800: #8c8c8c;
$search-gray-93: #ededed;
$search-gray-900: #777;
$search-gray-1000: #333;
$search-gray-1100: #e0e0e0;
$search-gray-1200: #e6e6e6;
$search-gray-1250: rgb(0 0 0 / 25%);
$search-gray-1300: #262626;
$search-gray-1400: #eaeaea;
$search-gray-1500: #4a4a4a;
$search-gray-1600: #f7f7f7;
$search-yellow-f5cc00: #f5cc00;
$search-yellow-fff1cb: #fff1cb;
$search-brown-a47b2e: #a47b2e;
$search-green-50: #e6f7ee;
$search-green-100: #00a650;
$search-green-150: #39b54a;
$search-green-200: #26a65b;
$search-blue-dropdown-nonselected: #9cc4db;
$search-zrp-disclaimer: #5c95ff;
$seach-background-spotlight-off: #d8d8d8;
$seach-background-fade-add-to-cart: rgb(255 255 255 / 60%);
$search-blue-dropdown-nonselected: #9cc4db;
$search-dodger-blue: #3483fa;
$search-blue-600: #1259c3;
$search-view-option-tooltip-link: #2968c8;
$search-background-button-cart: rgb(52 131 250 / 15%);
$search-shadow-tag-color: rgb(128 128 128 / 5%);
$search-shadow-thumbnail-color: rgb(128 128 128 / 10%);
$search-billboard-gray: rgb(245 245 245);
$search-billboard-black-700: rgb(0 0 0 / 70%);
$search-orange-100: #f73;
$search-blue-pill: rgb(52 131 250 / 20%);
$search-blue-pill-radius: 3px;

// Melicoin Colors
$search-melicoin-color-bg-pill: $search-yellow-fff1cb;
$search-melicoin-color-text-pill: $search-brown-a47b2e;

// Reputation Colors
$search-reputation-level-1: #ff605a;
$search-reputation-level-2: #ffb656;
$search-reputation-level-3: #fff044;
$search-reputation-level-4: #bbff1f;
$search-reputation-level-5: #31b93c;

// Alpha Colors
$search-gray-r010: rgb(0 0 0 / 10%);
$search-gray-r020: rgb(0 0 0 / 20%);
$search-gray-r025: rgb(0 0 0 / 25%);
$search-gray-r050: rgb(0 0 0 / 50%);
$search-gray-r055: rgb(0 0 0 / 55%);
$search-gray-r060: rgb(0 0 0 / 60%);
$search-gray-r080: rgb(0 0 0 / 80%);
$search-gray-r090: rgb(0 0 0 / 90%);
$search-gray-r007: rgb(0 0 0 / 7%);
$search-gray-120: rgb(0 0 0 / 12%);
$search-gray-004: rgb(0 0 0 / 4%);
$search-gary-12: rgb(0 0 0 / 12%);

// Component Colors
$search-color-warning: $search-yellow-f5cc00;
$search-result-gallery-shadow-color: $search-gray-r010;
$search-box-bg-color: $search-gray-300;
$search-box-border-color: $search-gray-400;
$search-bg-color: $search-gray-200;
$search-line-color: $search-gray-500;
$search-text-color: $search-gray-1000;
$search-color-filter: $search-gray-100;
$search-res-location-color: $search-gray-800;
$top-keywords-item: $search-gray-900;
$search-ad-label-color: $search-gray-600;
$search-details-color: $search-gray-800;
$search-suggestion-success-color: $search-green-200;

// Filter colors
$filter-colors: (
  2450293: #1717ff,
  2450308: #fff,
  2450295: #000,
  2450312: #fcb1be,
  2450307: #f00,
  2450299: #666,
  2450294: #e1e1e1,
  2450291: #a0522d,
  2450326: #ff00ec,
  2450314: #0da600,
  2450325: #0f5299,
  2450303: #cbcfd0,
  2450322: #830500,
  2450311: #9f00ff,
  2450280: #fadbe2,
  2450278: #83ddff,
  2450296: #ffed00,
  2450281: #f5f3dc,
  2450306: #013267,
  2450327: #ff8c00,
  2450286: #ffe4c4,
  2450302: #6fa8dc,
  2450310: #3f7600,
  2450289: #ffd700,
  2450319: #9ff39f,
  2450292: #af8650,
  2450318: #fa8072,
  2450290: #4e0087,
  2450283: #e0ffff,
  2450324: #003d00,
  2450298: #dcecff,
  2450288: #1e6e7f,
  2450284: #ff51a8,
  2450320: #40e0d0,
  2450309: #bf9000,
  2450285: #d06ea8,
  2450321: #cc87ff,
  2450304: #eacb53,
  2450279: #7a64c6,
  2450313: #f9ac95,
  2450300: #ffffe0,
  2450287: #faebd7,
  2450317: #c63633,
  2450301: #f0e68c,
  2450316: #d9d2e9,
  2450315: #0ff,
  2450297: #5d3806,
  2450328: #d2691e,
  2450305: #73e129,
  2450282: #9b3f14,
  2450323: #fdaf20,
  11282034: #cd5b45,
  11282036: #dab6fc,
  182017701798: #d3d3d3,
  11282033: #f0e68c,
  11282035: #d3d3d3,
);

// Z-index

$z-index-xl: 10;
$z-index-l: 8;
$z-index-m: 5;
$z-index-s: 2;
$z-index-base: 1;
$z-index-xxl: 99999;

// PI
$search-pi-bg-color: #eee;

// Transitions

$transition-duration: 350ms;
$quick-transition-duration: 200ms;

// Sizes
$gallery-cards-image-height: 284px;
$gallery-cards-image-width: 284px;
$gallery-small-cards-image-width: 240px;
$gallery-small-cards-image-height: 240px;
$gallery-srv-cards-image-height: 214px;
$gallery-srv-small-cards-image-height: 180px;
$gallery-mot-cards-image-height: 214px;
$gallery-mot-small-cards-image-height: 180px;
$default-body-width: 1200px;
$breakpoint-360: 360px;
$breakpoint-340: 340px;
$colors: (
  solid_black: $andes-black,
  light_green: $andes-success-green,
  solid_gray: $andes-gray-550,
);
$sizes: (
  xxx_small: $font-size-10,
  xx_small: $font-size-12,
  x_small: $font-size-14,
  small: $font-size-16,
  medium: $font-size-18,
  large: $font-size-20,
  x_large: $font-size-24,
);
$weights: (
  light: $font-weight-light,
  normal: $font-weight-normal,
  regular: $font-weight-regular,
  semibold_light: $font-weight-semi-bold-light,
  semibold: $font-weight-semibold,
  bold: $font-weight-bold,
);
$vehicle-body-types-small-bg-positions: (
  hatchback: -5px -89px,
  sedan: -176px -89px,
  suv: -175px -130px,
  pickup: -176px -5px,
  monovolumen: -91px -130px,
  cabriolet: -5px -5px,
  coupe: -90px -5px,
  furgon: -90px -47px,
  light_truck: -90px -89px,
  minivan: -5px -130px,
  off_road: -5px -173px,
  rural: -90px -173px,
  van: -176px -173px,
  crossover: -5px -47px,
  sports: -175px -47px,
);
$vehicle-body-types-bg-positions: (
  hatchback: -7px -176px,
  sedan: -377px -175px,
  suv: -377px -258px,
  pickup: -377px -6px,
  monovolumen: -191px -259px,
  cabriolet: -5px -5px,
  coupe: -191px -5px,
  furgon: -191px -91px,
  light_truck: -191px -173px,
  minivan: -5px -257px,
  off_road: -5px -341px,
  rural: -191px -342px,
  van: -377px -343px,
  crossover: -5px -89px,
  sports: -377px -91px,
);
$disclaimer-asterisk: '*';
