.#{$search-prefix}-breadcrumb {
  &__chevron {
    @include size(0.8em, 0.8em);

    transform: translateY(1px);
  }

  &__title {
    color: $search-gray-1000;
    display: block;
    font-size: $font-size-20;
    font-weight: $font-weight-semibold;
    line-height: 25px;
    padding-right: 16px;
    position: relative;
    word-wrap: break-word;
    z-index: 1;
  }

  &__link {
    color: $search-gray-1000;
    pointer-events: none;
  }

  .andes-breadcrumb {
    margin: 0;
    margin-bottom: $andes-spacing-16;
    font-weight: $font-weight-regular;
    line-height: 1.45;
    padding-inline-end: $search-spacing-6;

    & .andes-breadcrumb__chevron {
      margin: 0 4px;
      padding: 0;
      display: inline;
      float: none;
      color: $search-gray-666;
      stroke-width: 1.3;
    }

    & .andes-breadcrumb__item {
      display: inline;
    }

    .andes-breadcrumb__link,
    & .andes-breadcrumb__label {
      color: $search-gray-666;
      float: none;
    }

    & .andes-breadcrumb__link:hover {
      color: $search-gray-1000;
    }
  }

  .mr-5 {
    margin-right: 5px;
  }

  .max-width {
    width: 245px;
  }

  .full-icon-breadcrumb {
    width: 75px;
    position: relative;
    bottom: -6px;
  }
}
