.#{$search-prefix}-icon {
  &--chevron {
    stroke: $andes-blue-500;

    svg path {
      stroke-width: 1.5;
    }
  }

  &--warning {
    fill: $search-color-warning;
  }

  &--search {
    fill: $andes-gray-550;
  }

  &--full {
    fill: $andes-success-green;
  }

  &--loyalty {
    fill: $search-green-150;
  }

  &--full-super-small,
  &--full-super-small-mlb {
    transform: translateY(2px);
    overflow: unset;
  }

  &--hidden {
    display: none;
    min-width: 0;
    max-width: 0;
    min-height: 0;
    max-height: 0;
    opacity: 0;
  }

  &--alert-new,
  &--alert-edit {
    width: 22px;
    fill: $andes-blue-500;
  }

  &--disclaimer {
    &__container {
      background-color: $andes-blue-500;
      width: 16px;
      height: 16px;
      text-align: center;
      border-radius: 100%;
    }

    &__content {
      fill: $andes-white;
      width: 8px;
      height: 8px;
      position: relative;
      bottom: $andes-spacing-4;
    }
  }

  &--shippingtruck {
    fill: $andes-white;
  }

  &--badge {
    stroke: $andes-success-green;
    width: 18px;
    height: 19px;
  }

  &--meli {
    @include size(21px, 17px);
  }

  &--international-logo {
    @include size(213px, 48px);
  }
}

.ui-search-row {
  .ui-search-icon {
    &--international-logo {
      @include size(213px, 39px);
    }
  }
}
